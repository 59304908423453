.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.container {
  width: 100%;
  padding: 20px 0 80px;
  max-width: 900px;
  display: flex;
  flex-direction: column;
}


.containerTable {
  width: 100%;
  padding: 20px 0 80px;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
}

/* 약관 박스 필드 */
.listBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.listBox p {
  font-size: 16px;
  margin-bottom: 12px;
}

.textFild {
  width: 100%;
  height: 150px;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  font-size: 14px;
  color: #666;
  overflow: auto;
}

.space_between {
  display: flex;
  justify-content: space-between;
}

input[type='checkbox'] {
  display: none;
}

input[type='checkbox']+label {
  display: inline-block;
  cursor: pointer;
  padding-left: 28px;
  background: url('../../@assets/apply/check-disable.svg') left/20px no-repeat;
}

input[type='checkbox']:checked+label {
  background-image: url('../../@assets/apply/check-able.svg');
}

/* 안내박스 필드 */
.infodesk {
  width: 100%;
  display: flex;
  padding: 18px 0px;
  font-size: 14px;
  align-items: flex-start;
  border-radius: 10px;
  margin-top: 20px;
}

.infodesk img {
  margin-right: 8px;
  margin-top: 4px;
}

.container h1 {
  font-size: 26px;
  text-align: left;
  color: #316bb9;
  margin: 40px 0 40px;
  font-weight: bold;
}

/* 
회원정보 필드 */
.kor_field {
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
}

.label {
  font-size: 14px;
  margin: 12px 0 8px;
}

.inputBox {
  height: 48px;
  padding: 14px 12px;
  border: 1px solid #ccc;
  border-radius: 106px;
}

.inputBox::placeholder {
  color: #ccc;
}

.errorMsg {
  font-size: 13px;
  color: #e62d47;
  margin-top: 2px;
}

/* 작성 가이드 보기 박스 */
.guide_download_box {
  width: 100%;
  align-items: left;
  justify-content: left;
  padding: 15px;
  background-color: #f5fafb;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.guide_download_box p {
  font-size: 16px;
  color: #666;
  margin-right: 20px;
  font-style: bold;
  padding: 10px 0px;
}

.guide_download_box span {
  width: 140px;
  height: 40px;
  background-color: #316bb9;
  color: #fff;
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.guide_download_box span:hover {
  background-color: #245ca9;
}

/* Report of Return License 필드 */
.eng_field {
  display: flex;
  width: 100%;
}

.eng_field_container {
  width: 100%;
  flex-direction: column;
}

.serial_wrap {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 10px;
}

.serial_box {
  width: 100%;
}

.serialBtn_box {
  display: flex;
}

.serialBtn1,
.serialBtn2 {
  min-width: 50px;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin-left: 10px;
}

.serialBtn1 {
  background-color: #eee;
}

.serialBtn2 {
  background-color: #316bb9;
}

.radioGroup {
  display: flex;
  flex-direction: column;
}

.radioBox {
  display: flex;
  cursor: pointer;
  margin-top: 6px;
}

.form-check {
  position: relative;
}

input[type='radio'] {
  position: absolute;
  left: 0px;
  cursor: pointer;
}

input[type='radio']+label {
  display: inline-block;
  cursor: pointer;
  padding-left: 28px;
  background: url('../../@assets/apply/check-disable.svg') left/20px no-repeat;
  cursor: pointer;
}

input[type='radio']:checked+label {
  background-image: url('../../@assets/apply/check-able.svg');
  cursor: pointer;
}

/* 파일첨부 여부 필드 */
.fileBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #cccccc;
  background-color: #f5fafb;
  border-radius: 10px;
  padding: 14px;
  margin: 0px 0 20px;
}

.subBtn_box {
  width: 100%;
  display: flex;
  column-gap: 12px;
  margin-bottom: 14px;
}

.subBtn {
  width: 100%;
  border-color: transparent;
  background-color: #316bb9;
  border-radius: 0px;
  height: 50px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.subBtn_disabled {
  width: 100%;
  border-color: transparent;
  background-color: #cccccc;
  border-radius: 0px;
  height: 50px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}


.subBtn:hover {
  background-color: #245ca9;
}

.mainBtn {
  width: 100%;
  height: 50px;
  border-radius: 0px;
  border-color: #316bb9;
  background-color: #316bb9;
}

.mainBtn:hover {
  background-color: #245ca9;
}

.btn {
  border-color: #316bb9;
  background-color: #316bb9;
}

.btn-primary {
  border-color: #316bb9;
  background-color: #316bb9;
}