.wrapper {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 234px);
  display: flex;
  justify-content: center;
  background-color: #fcfdfd;
  align-items: center;
}

.container {
  width: 100%;
  height: 100%;
  max-width: 910px;
  padding: 80px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* 타이틀 */
.comp_title {
  display: flex;
  align-items: center;
  padding: 30px;
  background-color: #fff;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.06);
  width: 100%;
}

.comp_title img {
  width: 140px;
}

.comp_title h1 {
  font-size: 16px;
  margin-left: 20px;
}

/* 컴포넌트 박스 */
.comp_wrap {
  width: 100%;
  display: flex;
  margin-top: 20px;
  column-gap: 30px;
}

.comp_box {
  width: 100%;
  background-color: #fff;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.06);
  padding: 30px 25px;
  display: flex;
  flex-direction: column;
}

/* 좌측 컴포넌트 박스 */

/* === */
.linkbox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px;
  margin-bottom: 10px;
  position: relative;
  height: 60px;
}

.linkbox1 {
  background-color: #316bb9;
  color: #fff;
}

.linkbox1:hover {
  color: #fff;
  background-color: #2a5ea3;
}

.linkbox2 {
  border: 1px solid #316bb9;
  color: #316bb9;
}

.linkbox2:hover {
  background-color: #f4f7fb;
  border: 1px solid #2a5ea3;
  color: #2a5ea3;
}

.linkbox img {
  position: absolute;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);
}

/* === */
.guide_wrap {
  width: 100%;
  display: flex;
  column-gap: 10px;

}

/* === */
.guide_wrap_non_margin {
  width: 100%;
  display: flex;
  column-gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.guide_box {
  background-color: #f4f7fb;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 16px;
  border: 1px solid #f3f3f3;
  border-radius: 12px;
}

.guide_box:hover {
  background-color: #ebf1f9;
}

.guide_box img {
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.06);
  border-radius: 100px;
  margin-bottom: 14px;
}

.guide_box h3 {
  font-size: 18px;
  margin-bottom: 4px;
}

.guide_box span {
  display: flex;
  align-items: center;
  color: #316bb9;
}

.guide_box span img {
  margin: 0 0 0 4px;
}

/* === */
.comp1_title {
  font-size: 20px;
  font-weight: 600;
  margin: 15px 0 2px;
}

.info_box {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 20px;
  background: #fafafa;
  border-radius: 12px;
  margin-top: 10px;
}

.info_box img {
  width: 32px;
  margin: 4px 14px 0 0;
}

.info_box p {
  white-space: pre-line;
  color: #797979;
}

.info_box p span {
  font-weight: 500;
}

/* 우측 컴포넌트 박스 */

.comp2_title {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}

.comp2_subTitle {
  color: #797979;
  margin-top: 6px;
}

.ing_wrap {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.ing_box {
  width: 100%;
  display: flex;
}

.ing_box img {
  margin-right: 20px;
}

.textBox h4 {
  font-size: 18px;
  font-weight: 500;
}

.textBox h4 span {
  font-size: 14px;
  font-weight: 400;
  color: #316bb9;
  margin-left: 4px;
}

.textBox p {
  margin-top: 6px;
}

.flowBox {
  width: 100%;
  padding: 6px;
  text-align: center;
  border-radius: 10px;
  background-color: #fafafa;
  border: 1px solid #eee;
}