header {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 80px;
  border-bottom: 2px solid #eee;
}

header a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.apply_header {
  align-items: center;
  justify-content: flex-start;
  max-width: 900px;
  border-bottom: none;
}

.apply_header img {
  width: 120px;
}

.apply_header h1 {
  font-size: 16px;
  margin-left: 16px;
}

/* 푸터 */
footer {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #fafafa;
}

.container {
  width: 100%;
  max-width: 910px;
  display: flex;
  padding: 30px 20px;
  justify-content: center;
  align-items: flex-start;
}

.container img {
  margin-right: 40px;
  opacity: 0.5;
}

.container p {
  font-size: 0.813rem;
  color: #999;
}

.container p span {
  font-size: 0.725rem;
  color: #aaa;
}