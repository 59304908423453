@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700&display=swap');

* {
  font-family: 'Noto Sans KR', sans-serif;
  color: #333;
  list-style: none;
  text-decoration: none;
}

body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: none;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #333;
  outline: none;
}

a:hover {
  color: #333;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

button {
  outline: none;
  border: none;
  border-radius: 100px;
  padding: 0;
  margin: 0;
  background-color: transparent;
}

h1,
h2,
h3,
h4 {
  padding: 0;
  margin: 0;
}

p {
  padding: 0;
  margin: 0;
}

input[type='search'] {
  outline: none;
  border: none;
  background-color: transparent;
}

input:focus {
  outline: none;
}

input::placeholder {
  color: #ccc;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

input:-webkit-autofill {
  box-shadow: 0 0 0 1000px transparent inset;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 5px;
}

textarea {
  resize: none;
}

textarea:focus {
  outline: none;
}

textarea::-webkit-scrollbar {
  display: none;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
