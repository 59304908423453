.wrapper {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 240px);
  display: flex;
  justify-content: center;
}

.container {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 240px);
  max-width: 1100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info_desk h1 {
  font-size: 20px;
  font-weight: 600;
  margin: 16px 0 20px;
}

.info_desk p {
  font-size: 18px;
  color: #666;
}

.info_desk span {
  width: 100%;
  display: flex;
  align-items: center;
  color: #797979;
  margin-top: 26px;
}

.info_desk span img {
  margin-right: 8px;
}

/* 폼박스 */
.formBox_wrapper {
  width: 100%;
  max-width: 480px;
  display: flex;
  background-color: #fff;
  border: 1px solid #eeeeee;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.045);
  border-radius: 16px;
}

.formBox_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px 20px 30px;
}

.input_Field {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.label {
  font-size: 14px;
  margin: 12px 0 2px;
}

.inputBox {
  width: 100%;
  height: 48px;
  padding: 14px 12px;
  border: 1px solid #ccc;
  border-radius: 106px;
}

.inputBox::placeholder {
  color: #ccc;
}

.errorMsg {
  font-size: 13px;
  color: #e62d47;
  margin-top: 2px;
}

.robot {
  margin: 20px 0;
  width: 300px;
  height: 80px;
  cursor: pointer;
}

.btn {
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  height: 48px;
  background-color: #316bb9;
  color: #fff;
  border-radius: 8px;
}

.btn:hover {
  background-color: #245ca9;
}